/* eslint-disable consistent-return */
// 请注意，在 "vue-router": "4" 版本中，Vue推荐使用return而非next()。
// import axios from 'axios'
import mixpanel from 'mixpanel-browser'
import { find, isEmpty } from 'lodash-es'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { RouteLocationNormalized } from 'vue-router'

export default {
  init: async () => {
    const globalStore = useGlobalStore()
    if (isEmpty(globalStore.languages)) {
      await globalStore.globalConfigInit()
      if (import.meta.env.MODE === 'production') {
        mixpanel.init('aff4d1374175eb1ad6fd9b71e8a64e4c', {
          debug: true,
          track_pageview: true,
          persistence: 'localStorage'
        })
      }
    }
  },
  versionCheck: async () => {
    if (process.env.VITE__APP_COMMAND__ === 'serve') return
    // 判断是否与生成的版本信息一致
    const currentVersion = localStorage.getItem('UPDATE_VERSION')
    if (currentVersion === null) {
      localStorage.setItem('UPDATE_VERSION', String(process.env.VITE__APP_VERSION__))
    }
    const newVersion = localStorage.getItem('UPDATE_VERSION')
    if (newVersion && String(process.env.VITE__APP_VERSION__) !== newVersion) {
      window.location.reload()
      localStorage.setItem('UPDATE_VERSION', String(process.env.VITE__APP_VERSION__))
    }
  },
  authVerify: async (to: RouteLocationNormalized) => {
    // if (to.fullPath !== '/') {
    //   return { path: '/' }
    // }
    // return true
    // TODO: 维护中注释
    // eslint-disable-next-line no-unreachable, no-unreachable
    const globalStore = useGlobalStore()
    const userGlobalStore = useUserGlobalStore()
    const token = window.localStorage.getItem('elv-token')
    if (to.name === 'notFound') return

    if (!isEmpty(userGlobalStore.user) && !userGlobalStore.user?.name) {
      if (to.name !== 'login') {
        return { name: 'login', query: { state: 2 } }
      }
    }
    if (to.path === '/') {
      if (globalStore.projectList.length) {
        return { name: 'reports', params: { entityId: globalStore.entityListAll[0]?.entityId } }
      }
      return { name: 'landingPage' }
    }
    if (to.fullPath.indexOf('fundamentals-financials') !== -1) {
      return { name: 'landingPage' }
    }

    if (isEmpty(userGlobalStore.user) && token) {
      const userInitResult = await userGlobalStore.userInit()
      // 根据用户初始化结果 true正常走 false进前台首页
      if (!userInitResult) {
        return { name: 'landingPage' }
      }

      if (to.name === 'login' || to.name === 'signup') {
        if (globalStore.projectList?.length)
          return { name: 'reports', params: { entityId: globalStore.entityListAll[0]?.entityId } }
        return { name: 'landingPage' }
      }
    }
    if (
      to.matched.some((record) => record.name === 'reports') &&
      !find(globalStore.entityListAll, { entityId: Number(to.params?.entityId) })
    ) {
      return { name: 'landingPage' }
    }
    // 这个路由不需要验证
    if (!to.matched.some((record) => record.meta.requiresAuth)) return

    if (!token && to.name !== 'oauth-google') {
      return { name: 'login', query: to.redirectedFrom?.query }
      //   userGlobalStore.openLogin()
      //   return false
    }
  },
  mixpanelStatistics: async (to: RouteLocationNormalized) => {
    if (import.meta.env.MODE === 'development') return true
    mixpanel.track_pageview({ 'Page Name': to.name })
    return true
  }
}
