import { flatten, filter } from 'lodash-es'
import ConfigApi from '@/api/ConfigApi'
import ProjectApi from '@/api/ProjectApi'
import { ProjectItemType } from '#/ReportsTypes'
import { ProjectTrialDetailType } from '#/ProjectDetailTypes'
import { PlatformType, LanguagesType } from '#/GlobalConfigTypes'

export const useGlobalStore = defineStore({
  id: 'Global',
  state: () => {
    const globalState: {
      loaded: boolean
      languages: LanguagesType | null
      projectList: ProjectItemType[]
      platformList: PlatformType[]
      projectTrial: ProjectTrialDetailType
      showUpGradeInfo: boolean
    } = {
      languages: null, // 语言包
      loaded: false,
      projectList: [],
      projectTrial: {},
      platformList: [],
      showUpGradeInfo: false
    }
    return globalState
  },

  actions: {
    // 获取配置
    async globalConfigInit() {
      this.loaded = true
      let globalConfig: any
      if (this.languages === null) {
        globalConfig = new Promise((resolve, reject) => {
          ConfigApi.getGlobalConfig()
            .then((response) => {
              this.languages = response.data.languages
              this.platformList = response.data.platforms
              return resolve(response.data)
            })
            .catch((e) => {
              return reject(e)
            })
        })
      }

      const token = window.localStorage.getItem('elv-token')
      let projectList: any
      if (token) {
        projectList = new Promise((resolve, reject) => {
          ProjectApi.getProjectList()
            .then((response) => {
              this.projectList = response.data
              return resolve(response.data)
            })
            .catch((e) => {
              return reject(e)
            })
        })
      }

      try {
        let requestList = [globalConfig]
        if (token && this.languages === null) {
          requestList = [globalConfig, projectList]
        } else if (token && this.languages !== null) {
          requestList = [projectList]
        }
        const response = await Promise.all(requestList)
        this.loaded = false
        return response
      } catch (error: any) {
        this.loaded = false
        return Promise.reject(error)
      }
    },
    /**
     * 获取项目试用详情
     * @param {number} entityId 项目id
     */
    async fetchProjectTrialDetail() {
      try {
        const response = await ProjectApi.getProjectTrialDetail()
        this.projectTrial = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取项目列表
     */
    async fetchProjectList() {
      try {
        const response = await ProjectApi.getProjectList()
        this.projectList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {
    entityListAll: (state) => {
      // 使用lodash将state.projectList所有中的entityList合并
      const a = flatten(state.projectList.map((item) => item.entityList))
      return a
    },
    chainList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'CHAIN')
    },
    bankList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'BANK').sort((a) => {
        if (a?.platformId === 31) {
          return 1
        }
        return -1
      })
    },
    exchangeList: (state): PlatformType[] => {
      return filter(state.platformList, (item) => item.type === 'EXCHANGE').sort((a, b) => {
        if (a.pro === b.pro) {
          return a.name.localeCompare(b.name)
        }
        return b.pro - a.pro
      })
    }
  }
})
