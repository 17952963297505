import { User, NavigationSortType } from '#/UserTypes'
import UserApi from '@/api/UserApi'
import mixpanel from 'mixpanel-browser'

export const useUserGlobalStore = defineStore({
  id: 'userGlobal',
  state: () => {
    const userGlobalState: {
      user: User | null
      isShowLogin: boolean
      isShowLoginDialog: boolean
      navigationSort: NavigationSortType[]
    } = {
      user: null,
      isShowLogin: false,
      isShowLoginDialog: false,
      navigationSort: []
    }
    return userGlobalState
  },
  getters: {
    isLogin: (state: any) => {
      return Boolean(state.user?.userId)
    }
  },
  actions: {
    // 打开登录弹窗
    loginVisible() {
      this.isShowLogin = true
    },
    // 关闭登录弹窗
    loginHidden() {
      this.isShowLogin = false
    },
    // 打开登录弹窗
    openLogin() {
      this.isShowLoginDialog = true
    },
    // 关闭登录弹窗
    closeLogin() {
      this.isShowLoginDialog = false
    },
    //   用户退出登录
    async logout() {
      window.localStorage.setItem('onlyId', String(this.user?.userId))
      this.user = null
      this.navigationSort = []
      window.localStorage.removeItem('elv-token')
      this.$reset()
    },

    /** 初始化用户信息 */
    async userInit() {
      // 获取登录用户的信息
      try {
        const { data } = await UserApi.getUser()
        this.user = data
        this.navigationSort = data.navigationSort
        if (import.meta.env.MODE === 'production') {
          mixpanel.identify(data.email)
        }
        return true
      } catch (errors: any) {
        console.log(errors)
        if (errors.message === 'Unauthorized' || errors.code === 10000) {
          if (this.user?.userId) {
            window.localStorage.setItem('onlyId', String(this.user?.userId))
          }
          this.user = null
          window.localStorage.removeItem('elv-token')
        }
        return false
      }
    }
  }
})
